@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');

.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #404041;
  color: white;
  font-family: 'Barlow', sans-serif;
  padding: 0 20px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.contact-info {
  margin-top: 20px;
  margin-bottom: 30px;
}

.contact-info p {
  font-size: 18px;
  margin: 5px 0;
}

.contact-info a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
}

.contact-info a:hover {
  color: #0E76BB;
  border-bottom: 1px solid #0E76BB;
}

.services {
  background-color: #0E76BB;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

.services h2 {
  margin: 0 0 10px 0;
}

.services ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.services li {
  font-size: 18px;
  margin: 5px 0;
}
